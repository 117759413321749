import myAxios from '../utils/request'

// 同步机器人群信息
const ApiSyncRobotChatRoom = (robotWxId = '') => {
  return myAxios.get('Common/SyncRobotChatRoom', {
    params: {
      robotWxId: robotWxId
    }
  })
}

// 同步群成员
const ApiSyncChatroomMember = (data = {}) => {
  return myAxios.post('Common/SyncChatroomMember', {
    robotWxId: data.robotWxId,
    chatroomId: data.chatroomId
  })
}

export {
  // 同步机器人群信息
  ApiSyncRobotChatRoom,
  // 同步群成员
  ApiSyncChatroomMember
  
}
