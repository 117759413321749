import myAxios from '../utils/request'
// 机器人上号
const ApiGetPorts = (data = {}) => {
    return myAxios.post('/WxRobot/GetPorts', {
        wxType: 1
    })
}
// 机器人上号
const ApiWxRobotLogin = (data = {}) => {
    return myAxios.post('/WxRobot/WxRobotLogin', {
        // 设备号
        deviceId: data.deviceId,
        // 机器人编号
        robotWxId: data.robotWxId,
        // 过期时间
        expireDate: data.expireDate,
        // 客户端IpclientIp
        clientIp: data.clientIp,
        // 1个人微信,2企业微信
        robotWxType: data.robotWxType
    })
}

// 机器人注销
const ApiWxRobotLogout = (data = {}) => {
    return myAxios.post('/WxRobot/WxRobotLogout', {
        // 设备号
        userCode: data.userCode,
        // 机器人编号
        robotWxId: data.robotWxId,
        // 设备号
        deviceId: data.deviceId,
        // 1个人微信,2企业微信
        wxType: data.wxType,
        // [{  reasonType: 1,  reasonData: '默认'}]
        reason: data.reason
    })
}
// 获取登录结果
const ApiGetWxLoginResult = (soNumber = '') => {
    return myAxios.get('/WxRobot/GetWxLoginResult', {
        params: {
            soNumber: soNumber
        }
    })
}
// 设置登录验证码
const ApiSetWxLoginVerifyCode = (data = {}) => {
    return myAxios.post('/WxRobot/SetWxLoginVerifyCode', {
        // 操作编号
        soNumber: data.soNumber,
        // verifyCode
        verifyCode: data.verifyCode,
    })
}

// 查询机器人群信息
const ApiQueryChatrooms = (data = {}) => {
    return myAxios.post('/WxRobot/QueryChatrooms', {
        groupName: data.groupName,
        pageIndex: data.pageIndex,
        pageSize: data.pageSize,
        sortName: '',
        sortOrder: 0,
        // []
        robotWxIds: data.robotWxIds,
        // 模糊查询

        likeName: data.likeName,
        // 1等待开通群,2已经开通群,null开群状态不做筛选
        openState: data.openState,
        // null忽略该筛选（只有OpenState=2时才需要配置此选项,不然给null值） 1所有开通群（含别人开通）,3当前用户开通群
        openType: data.openType
    })
}


export {
    // 机器人上号
    ApiGetPorts,
    // 机器人上号
    ApiWxRobotLogin,
    // 机器人注销
    ApiWxRobotLogout,
    // 获取登录结果
    ApiGetWxLoginResult,
    // 设置登录验证码
    ApiSetWxLoginVerifyCode,
    // 查询机器人群信息
    ApiQueryChatrooms,
}
