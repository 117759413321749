<template>
  <div class="page ">
    <van-sticky>
      <div class="flex bg-white">
        <van-search v-model="likeName"
                    placeholder="搜索"
                    shape="round"
                    class="flex-1"
                    @search="search"/>
        <van-button type="primary"
                    round
                    size="small"
                    class="mr-3"
                    style="padding: 4px 13px"
                    @click="search">搜索
        </van-button>
        <van-button type="primary"
                    plain
                    round
                    size="small"
                    class="mr-3"
                    style="padding: 4px 13px"
                    @click="syncGroup">刷新
        </van-button>
      </div>
    </van-sticky>
    <van-list v-model:loading="loading"
              :finished="finished"
              finished-text=""
              @load="getData">
      <div class="list-content mt-2 flex-1" v-if="groupList.length">
        <van-checkbox-group v-model="checkValue" :max="5">
          <van-checkbox
              v-for="(el, index) in groupList"
              class="mb-2 group-checkbox bg-white"
              :disabled="el.openState === 2"
              :key="el.chatroomId"
              :name="el.chatroomId"
          >
            <InfoCell :customerStyle="{ paddingLeft: '2px'}" :avatar="el.groupHead" avatar-height=".44rem"
                      :round="false"
                      align-items="flex-start">
              <template #context>
                <div class="flex column v-top ml-1">
                  <div class="text-flow color-info" :class="{'color-info' : el.openState === 2}">
                    {{ el.groupName ? el.groupName : '未命名' }}
                  </div>
                  <small v-if="el.openState === 2" class="color-info mt-1 text-flow open-state">
                    <template v-if="el.isCollectChatroom">
                      此群为采集对象，不可托管
                    </template>
                    <template v-else>
                      此群已开通机器人，开通人：{{ el.openChatroomRobotName ? el.openChatroomRobotName : '***' }}
                    </template>
                  </small>
                </div>

              </template>
            </InfoCell>

          </van-checkbox>
        </van-checkbox-group>
      </div>
    </van-list>
    <div class="bg-white"  v-if="groupList.length === 0">
      <no-data :showButton="false" height="2.1rem" class="bg-white mt-2"
               title="暂无数据哦！"/>
      <div class="flex v-top h-center pb-6">
        Tips: <p style="width: 2.2rem">小助手加载微信群需要一段时间，稍等一会儿多刷几次哦~</p>
      </div>
    </div>

    <div class="fixed_bottom flex">
      <div class="color-gray">已选择</div>
      <div class="color-danger">（{{ isCheckedLength }}/ 5）</div>
      <button class="btn flex flex-right h-center button"
              @click="handleOpenGroup">确定
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Notify, Toast} from 'vant'
import {ApiAddFollowLiveChatroom} from '../../api/FollowLive'
import {ApiSyncRobotChatRoom} from '../../api/Common'
import {defineAsyncComponent} from 'vue'
import {ApiQueryChatrooms} from "../../api/WxRobot";

export default {
  name: 'Index',
  components: {
    InfoCell: defineAsyncComponent(() => import('../../components/InfoCell'))
  },
  data() {
    return {
      loading: false,
      finished: false,

      pageIndex: 1,
      pageSize: 15,
      likeName: '',
      // 被选中过的值
      isCheckedValue: [],
      // 当前选中值
      checkValue: [],
      groupList: [],
      additionalVal: {
        totalUserOpen: 0
      }
    }
  },
  created() {
    // this.getData()
  },
  computed: {
    ...mapGetters(['robotInfo']),
    isCheckedLength() {
      return this.checkValue.length
    }
  },
  methods: {
    search() {
      this.isCheckedValue = []
      this.groupList = []
      this.finished = false
      this.pageIndex = 1
      this.getData()
    },
    async getData() {
      this.loading = true
      try {
        const res = await ApiQueryChatrooms({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          sortName: '',
          sortOrder: 0,
          robotWxIds: [this.robotInfo.robotWxId],
          likeName: this.likeName
        })
        this.pageIndex++
        const dataList = res ? res.dataList : []
        dataList.forEach(i => {
          // 判断
          if (i.isTheirOpening ) {
            !this.isCheckedValue.includes(i.chatroomId) && this.isCheckedValue.push(i.chatroomId)
            !this.checkValue.includes(i.chatroomId) && this.checkValue.push(i.chatroomId)
          }
        })
        this.groupList = [...this.groupList, ...dataList]
        if (this.groupList.length >= res.recordCount) {
          this.finished = true
        }
        this.additionalVal = res.additionalVal
        this.loading = false
      } catch (e) {
        this.loading = false
        this.finished = true
        console.warn(e)
      }
    },
    async syncGroup() {
      const toast = Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '数据加载中...'
      })
      try {
        await ApiSyncRobotChatRoom(this.robotInfo.robotWxId)
        toast.clear()
        Notify({
          type: 'success',
          message: '刷新成功',
          duration: 1000
        })
        await this.search()
      } catch (e) {
        toast.clear()
        console.log(e)
      }
    },
    async handleOpenGroup() {
      // 开通的群id
      const OpenChatroomIds = this.checkValue.filter(i => {
        return !this.isCheckedValue.includes(i)
      })
      if (OpenChatroomIds.length === 0) {
        this.$Toast('请先选择群')
        return
      }
      const robotWxId = this.robotInfo.robotWxId
      const toast1 = Toast.loading({
        message: '开群中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      try {
        await ApiAddFollowLiveChatroom({
          robotWxId,
          chatroomIds: OpenChatroomIds
        })
        this.$Toast('开群成功')
        toast1.clear()
        this.$router.go(-1)
      } catch (e) {
        toast1.clear()
        console.log(e)
      }

    }
  }
}
</script>

<style scoped lang="scss">
.page {

  padding-bottom: 60px;

  .list-content {
    padding: 0 15px;
    overflow-y: auto;
  }

  .fixed_bottom {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 12px 15px;
    background-color: #fff;
  }

  .btn {
    width: 64px;
    height: 36px;
    background: #FF445E;
    border-radius: 99px 99px 99px 99px;
    color: rgba(255, 255, 255, .8);
  }

  .group-checkbox {
    height: 74px;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 8px 8px 8px 8px;

    .open-state {
      max-width: 220px;
    }
  }
}
</style>
