import myAxios from '../utils/request'
// 获取讲师 相当于微信群信息
const ApiGetTeacher = async (roomId = '') => {
  return await myAxios.get('/FollowLive/GetTeacher', {
    params: {
      roomId: roomId
    }
  })
}

// 设置跟发群的采集群
const ApiSetFollowRoomLiveTeacher = (data = {}) => {
  return myAxios.post('/FollowLive/SetFollowRoomLiveTeacher', {
    // 机器人wxid
    robotSerialNo: data.robotSerialNo,
    // 讲师群Id
    teacherChatroomId: data.teacherChatroomId,
    // 讲师清单
    /**
     * [
     * {
     *    teacherWxId: "",
     *    nickName: "",
     *    headImgUrl: ""
     * }
     * ]
     */
    teacherInfos: data.teacherInfos,
    // 0讲师模式,1全群模式
    routerType: data.routerType,
    // 群Id
    roomId: data.roomId,
  })
}
// 设置跟发官方素材群
const ApiSetFollowLiveChatroomToOfficalType = (data = {}) => {
  return myAxios.post('/FollowLive/SetFollowLiveChatroomToOfficalType', {
    // 群Id
    roomId: data.roomId,
    // 官方素材群编号
    officialChatroomSerialNo: data.officialChatroomSerialNo,
    // 官方分组ID
    officalId: data.officalId
  })
}
// 设置跟发群为采集模式
const ApiSetFollowLiveChatroomToUserType = (data = {}) => {
  console.log(data)
  return myAxios.post('/FollowLive/SetFollowLiveChatroomToUserType', {
    // 群Id
    roomId: data.roomId,
  })
}
// 查询群成员
  const ApiQueryChatroomMembers = (data = {}) => {
    return myAxios.post('/FollowLive/QueryChatroomMembers', {
      // 机器人wxid
      robotWxId: data.robotWxId,
      // 群Id
      chatroomId: data.chatroomId,
      //
      likeName: data.likeName,
      //
      roomId: data.roomId
    })
  }
// 获取群组信息
  const ApiGetChatrooms = (data = {}) => {
    return myAxios.post('/FollowLive/GetChatrooms', {
      groupName: data.groupName,
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      sortName: '',
      sortOrder: 0,
      robotWxId: data.robotWxId
    })
  }
// 获取采集素材时间段
  const ApiGetFollowTimeRange = (data = {}) => {
    return myAxios.get('/FollowLive/GetFollowTimeRange')
  }

// 设置采集素材时间段
  const ApiSetFollowTimeRange = (data = {}) => {
    return myAxios.post('/FollowLive/SetFollowTimeRange', {
      // 开始时间
      startTimeRange: data.startTimeRange,
      // 结束时间
      endTimeRange: data.endTimeRange
    })
  }

// 新增跟发群
  const ApiAddFollowLiveChatroom = (data = {}) => {
    return myAxios.post('/FollowLive/AddFollowLiveChatroom', {
      // 机器人wxid
      robotWxId: data.robotWxId,
      // [群Id]
      chatroomIds: data.chatroomIds
    })
  }

// 设置跟发状态
  const ApiSetFollowLiveChatroomState = (data = {}) => {
    return myAxios.post('/FollowLive/SetFollowLiveChatroomState', {
      // 群Id
      roomId: data.roomId,
      // 1跟发中 2停止跟发
      state: data.state
    })
  }



// 设置跟发类型
  const ApiSetFollowLiveChatroomType = (data = {}) => {
    return myAxios.post('/FollowLive/SetFollowLiveChatroomType', {
      // 机器人wxid
      robotWxId: data.robotWxId,
      // 群Id
      chatroomId: data.chatroomId,
      // 1.个人采集群，2.官方素材群
      type: data.type
    })
  }

// 查询跟发群列表
  const ApiQueryFollowLiveChatrooms = (data = []) => {
    return myAxios.post('/FollowLive/QueryFollowLiveChatrooms', data)
  }
// 查询官方群列表
  const ApiQueryOfficialChatrooms = (data = {}) => {
    return myAxios.post('/FollowLive/QueryOfficialChatrooms')
  }

// 查询官方群消息
  const ApiQueryOfficalChatRoomMsgs = (data = {}) => {
    return myAxios.post('/FollowLive/QueryOfficalChatRoomMsgs', {
      // 机器人wxid
      robotWxId: data.robotWxId,
      // 群Id
      chatroomId: data.chatroomId
    })
  }
// 查询官方分组列表
  const ApiOfficalPushGroupList = (data = {}) => {
    return myAxios.post('/FollowLive/OfficalPushGroupList')
  }
// 移除一个跟发群
  const ApiRemoveFollowLiveChatroom = (data = {}) => {
    return myAxios.post('/FollowLive/RemoveFollowLiveChatroom', {
      // 机器人wxid
      robotWxId: data.robotWxId,
      // 群Id
      chatroomId: data.chatroomId
    })
  }
  
  export {
    // 获取更发素材来源
    ApiGetTeacher,
    // 设置采集对象
    ApiSetFollowRoomLiveTeacher,
    // 设置跟发官方素材群
    ApiSetFollowLiveChatroomToOfficalType,
    // 设置跟发群为采集模式
    ApiSetFollowLiveChatroomToUserType,
    // 查询群成员
    ApiQueryChatroomMembers,
    // 获取群组信息
    ApiGetChatrooms,
    // 获取采集素材时间段
    ApiGetFollowTimeRange,
    // 设置采集素材时间段
    ApiSetFollowTimeRange,
    // 设置跟发群
    ApiAddFollowLiveChatroom,
    // 设置跟发状态
    ApiSetFollowLiveChatroomState,
    // 设置跟发类型
    ApiSetFollowLiveChatroomType,
    // 查询跟发群列表
    ApiQueryFollowLiveChatrooms,
    // 查询官方群列表
    ApiQueryOfficialChatrooms,
    // 查询官方群消息
    ApiQueryOfficalChatRoomMsgs,
    // 查询官方分组列表
    ApiOfficalPushGroupList,
    // 移除一个跟发群
    ApiRemoveFollowLiveChatroom
  }
